import "./religionenStartpageMap.css";

export const initReligionenStartpageMap = (ele: HTMLElement) => {
	ele.innerHTML = `
	<div class="wspmMap">
		<div class="wspmMapBackground">
			<div class="wspmBackgroundLeipzig" city-name="leipzig"></div>
			<div class="wspmBackgroundSchleife" city-name="schleife"></div>
			<div class="wspmBackgroundBautzen" city-name="bautzen"></div>
			<div class="wspmBackgroundDresden" city-name="dresden"></div>
			<div class="wspmBackgroundBadGottleuba" city-name="badGottleuba"></div>
			<div class="wspmBackgroundChemnitz" city-name="chemnitz"></div>
			<div class="wspmBackgroundZwickau" city-name="zwickau"></div>
		</div>
		<div class="wspmMapMarker">
			<div class="wspmMarkerLeipzig" city-name="leipzig">Leipzig</div>
			<div class="wspmMarkerSchleife" city-name="schleife">Schleife</div>
			<div class="wspmMarkerBautzen" city-name="bautzen">Bautzen</div>
			<div class="wspmMarkerDresden" city-name="dresden">Dresden</div>
			<div class="wspmMarkerBadGottleuba" city-name="badGottleuba">Bad Gottleuba-Berggie&szlig;h&uuml;bel</div>
			<div class="wspmMarkerChemnitz" city-name="chemnitz">Chemnitz</div>
			<div class="wspmMarkerZwickau" city-name="zwickau">Zwickau</div>
		</div>
	</div>
	<div class="wspmMapReligions">
		<div class="wspmReligionEvangelischSorbisch" city-name="schleife">
			<a href="/inhalt/evangelisch-sorbische-gemeinde-schleife">Evangelisch Sorbisch</a>
		</div>
		<div class="wspmReligionBosnischMuslimisch" city-name="leipzig">
			<a href="/inhalt/bosnisch-muslimische-gemeinde-leipzig">Bosnisch-Muslimisch</a>
		</div>
		<div class="wspmReligionAhmadiyya" city-name="zwickau">
			<a href="/inhalt/ahmadiyya-gemeinschaft-zwickau">Ahmadiyya</a>
		</div>
		<div class="wspmReligionDresden" city-name="dresden">
			<a href="/inhalt/hinduistische-gemeinschaft-dresden">Hinduistisch</a>
			<a href="/inhalt/eritreische-christentlich-orthodoxe-tewahedo-gemeinschaft-dresden">Eritreisch-Orthodox</a>
		</div>
		<div class="wspmReligionPolnischKatholisch" city-name="bautzen">
			<a href="/inhalt/polnische-katholische-gemeinschaft-bautzen">Polnischsprachig Katholisch</a>
		</div>
		<div class="wspmReligionVietnamesischBuddhistisch" city-name="badGottleuba">
			<a href="/inhalt/buddhistische-gemeinschaft-dresden">Vietnamesisch-Buddhistisch</a>
		</div>
		<div class="wspmReligionJuedischOrthodox" city-name="chemnitz">
			<a href="/inhalt/buddhistische-gemeinschaft-dresden">Jüdisch-Orthodox</a>
		</div>
	</div>
	`;
	const markers = Array.from(ele.querySelectorAll<HTMLElement>(".wspmMapMarker > div"));
	const activateMarker = (marker:HTMLElement) => {
		const city = marker.getAttribute("city-name");
		for(const n of ele.querySelectorAll<HTMLElement>(`div.active[city-name]`)){
			n.classList.remove("active");
		}
		for(const n of ele.querySelectorAll<HTMLElement>(`div[city-name="${city}"]`)){
			n.classList.add("active");
		}
	};

	let i = 0;
	const nextMarker = () => {
		activateMarker(markers[i]);
		i = (i + 1) % markers.length;
	};
	let interval:ReturnType<typeof setInterval> | undefined = setInterval(nextMarker, 4000);
	setTimeout(nextMarker,0);

	for(const marker of markers){
		marker.onclick = e => {
			e.preventDefault();
			e.stopPropagation();
			if(interval){
				clearInterval(interval);
				interval = undefined;
			}
			activateMarker(marker);
		};
	}
};

export const initAllReligionenStartpageMaps = () => document
	.querySelectorAll<HTMLElement>(".weltreligionenStartpageMap")
	.forEach(initReligionenStartpageMap);

setTimeout(initAllReligionenStartpageMaps, 0);
